<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="knowledge-base-page">
        <!-- JUMBOTRON -->
        <div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 py-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Oluşum Bilgi İşlem</h1>
                <h1 class="mb-1 text-white">En<strong> İyisiyle </strong> Çalıştığınızdan Emin Olmalısınız!</h1>
                <p class="text-white">Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat'tan Gıda'ya, Otomotiv'den Mobilya'ya, Demir-Çelik'ten Enerji'ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm'den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel çözümler sunuyoruz.</p>
                <vs-input placeholder="Arama Konusu veya Anahtar Kelime" v-model="knowledgeBaseSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full no-icon-border mt-6" />
            </div>
        </div>

        <!-- KNOWLEDGE BASE CARDS  -->
        <div class="vx-row">
                <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in filteredKB" :key="item.id" >
                    <vx-card class="text-center cursor-pointer">
                        <img :src="require(`@/assets/images/pages/${item.graphic}`)" alt="graphic" width="180" class="mx-auto mb-4">
                        <h4 class="mb-2">{{ item.title.toUpperCase() }}</h4>
                        <small>{{ item.description }}</small>
                    </vx-card>
                </div>
            </div>  
    </div>
</template>

<script>

export default{
    data() {
        return {
            knowledgeBaseSearchQuery: '',
            kb: [
                { id: 1, title: 'ALT YAPI KURULUMU', description: 'Şirketinizin A-Z ye Otomasyon Alt Yapısı Kurulumu ve Yönetimi.', graphic: 'graphic-1.png' ,url: '/pages/knowledge-base/category'},
                { id: 2, title: 'WEB TASARIM', description: 'Kurumsal Kimlik Web Sayfası Tasarım ve İçerik Yönetimi', graphic: 'graphic-2.png' ,url: '/pages/knowledge-base/category'},
                { id: 3, title: 'TİCARİ YAZILIM', description: 'İşletmelerin ihtiyaç duydukları tüm iş süreçlerini yöneten yazılım KARPİN', graphic: 'graphic-3.png' ,url: '/pages/knowledge-base/category'},
                { id: 4, title: 'E-TİCARET', description: 'İşinizi İnternete taşıyoruz.', graphic: 'graphic-4.png' ,url: '/pages/knowledge-base/category'},
                { id: 5, title: 'MOBİL UYGULAMA', description: 'Webten,Cepten,Tabletten İşinizi yönetmenizi sağlıyoruz.', graphic: 'graphic-5.png' ,url: '/pages/knowledge-base/category'},
                { id: 6, title: 'TEKNİK DESTEK', description: 'Bilgisiyar,Network,Printer,Uzak bağlantı,VPN,Cisco,Altyapınızı sağlıyoruz.', graphic: 'graphic-6.png' ,url: '/pages/knowledge-base/category'},
            ]
        }
    },
    computed: {
        filteredKB() { 
            return this.kb.filter((item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()));
        }
    },
    methods: {},
    components: {}
}
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
    background-image: url('../../assets/images/pages/knowledge-base-cover.jpg');
    background-size: cover;
}
</style>